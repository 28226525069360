<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h3 class="card-title"><b>LISTA DE MEDALLAS</b></h3>
          </template>
          <div class="text-right px-4 mt-3">
            <!--   -->
            <div class="row" style="gap: 1rem">
              <div class="col-12 col-md-10">
                <!-- linar-style cursor glow -->
                <CardTable :data="dataCards" />
              </div>
              <!-- btn new capacitacion -->
              <div class="col-12 col-md">
                <BtnAddCardTable
                  text="Agregar Nueva Medalla"
                  url="Medallas_agregar"
                />
              </div>
            </div>
          </div>
          <div class="table-responsive px-4 mt-3">
            <table
              id="datatable_areas"
              class="table table-bordered table-responsive-md table-striped text-center"
            >
              <thead>
                <tr>
                  <th style="width: 20%">Imagen Medalla</th>
                  <th>Nombre Medalla</th>
                  <th style="width: 20%">Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>
                    <img
                      style="width: 100px"
                      :src="item.imagen"
                      :alt="item.descripcion"
                    />
                  </td>
                  <td>
                    <p style="font-size: large">
                      {{ item.descripcion }}
                    </p>
                  </td>
                  <td
                    class="d-flex justify-content-center align-items-center"
                    style="height: 90px"
                  >
                    <!-- editar -->
                    <vs-button
                      success
                       
                      :to="`Medallas_editar/${item.id}`"
                    >
                      <i class="fas fa-solid fa-pen px-1"></i>Editar
                    </vs-button>
                    <!-- eliminar -->
                    <vs-button danger   @click="deleteRow(item.id)">
                      <i class="fas fa-solid fa-trash px-1"></i>Eliminar
                    </vs-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import BtnAddCardTable from "../../../components/btnCardTable/btnAddCardTable.vue";
import CardTable from "../../../components/cardTable/CardTable.vue";
import { core } from "../../../config/pluginInit";
const Swal = require("sweetalert2");

export default {
  name: "DataTable",
  data() {
    return {
      listaItems: [],
      logoEmpresa: "",
      usuario: {},
      row: {
        imageUrl: "",
        name: "",
      },
      dataCards: [
        {
          title: "Medallas",
          value: () => this.listaItems.length,
          iconName: "medal",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getData();
      core.index();
      window.$("#datatable_areas").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#186bdd",
        cancelButtonColor: "rgb(242, 19, 93)",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Medallas/Delete/" + id,
          });
          await this.getData();
          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Medallas/List/",
        });
        console.log("Medallas... ", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
  components: { CardTable, BtnAddCardTable },
};
</script>
<style lang="scss"></style>
